#message-modal {
    background-color: rgba(0, 0, 0, 0.80);
}
#message-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    padding: 2.0313vw 2.0313vw 5.1852vh;
    row-gap: 2.963vh;
    align-items: center;
    background-color: #101010;
    border-radius: 0.5208vw;
}

#message-modal-close-button {
    align-self: flex-end;
    position: relative;
    top: -1.015vw;
    right: -1.015vw;
}

#message-modal-image {
    width: 10.3125vw;
    padding-left: 1.5625vw;
}

#message-modal-text {
    margin: 0;
    color: #FFF;
    font-family: Inter;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

@media (max-width: 991px) { 
    #message-modal-content {
        padding: 10vw 10vw 10vw;
        row-gap: 5vh;
        border-radius: 5vw;
    }
    
    #message-modal-close-button {
        top: -5vw;
        right: -5vw;
    }
    
    #message-modal-image {
        width: 50vw;
        padding-left: 7.5758vw;
    }
    
    #message-modal-text {
        font-size: 4.0712vw;
    }
}